export const updateVal = {
    data() {
        return {
            selected: [],
        }
    },
    mounted() {
        if (sessionStorage.getItem(this.$route.name) !== 'undefined' && sessionStorage.getItem(this.$route.name)) {
            this.selected = JSON.parse(sessionStorage.getItem(this.$route.name))
            this.$refs.selectBox.setVal(this.selected)
        } else {
            this.selected = []
        }
    },
    methods: {
        updateVal(val) {
            this.selected = val;
        }
    }
}
