<template>
  <div class="step-content">
    <div class="d-block">
      <div class="title" v-html="$t('questions.step_7.title')"></div>
      <SelectBox @update-val="updateVal"
                 ref="selectBox"
                 :check-data="$t('questions.step_7.checkbox')"/>
    </div>
    <div class="d-flex justify-content-center">
      <RestartBtn/>
      <div class="btn" @click="result" :class="{disabled: !selected.length}">{{
          $t('questions.step_7.btnText')
        }}
      </div>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";
import {updateVal} from "../mixins/update-val";

export default {
  name: 'Step_7',
  mixins: [updateVal],
  components: {
    SelectBox
  },
  methods: {
    result() {
      let path = '';
      let answers = [];
      for (let i = 0; i < 8; i++) {
        answers.push(JSON.parse(sessionStorage.getItem(i.toString())));
      }
      try {
        answers.splice(-1, 1)
        // scenario 1
        if (answers[2].includes('No')
            && answers[3][0].includes('None')
            && answers[4][0].includes('No')
            && answers[5].includes('No')) {
          path = '/negative'
        } else if (answers[2].includes('Yes')
            // scenario 2
            && answers[3][0].includes('None')
            && Array.isArray(answers[3])
            && answers[5].includes('No')
            && answers[6].includes('No')) {
          path = '/negative'
        } else if (answers[5].includes('Yes') && answers[6].includes('Yes')) {
          // scenario 3
          path = '/negative'
        } else {
          path = '/positive'
        }
        sessionStorage.setItem('unlock-page', 'true')
        this.$router.push(path).catch(() => console.clear())
      } catch (e) {
        path = 'check-in/1'
        this.$router.push('/check-in/1').catch(() => console.clear())
      }
      return path
    }
  }
}
</script>
